var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"flat":"","items":_vm.contracts,"items-per-page":10},scopedSlots:_vm._u([{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticClass:"pointer"},[_vm._v(_vm._s(item.customer.full_name))])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.start_date,"DD MMM YY")))])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.end_date,"DD MMM YY")))])]}},{key:"item.controls",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.$router.push('contracts/'+item.id)}}},[_vm._v(" View ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }